export const DEFAULT_SERVICE = 'defaultService';

export const PRODUCT_API_URLS = [
  { text: 'EMEA', value: 'https://api-beautyhub-emea.loreal.io/bhub/products/' },
  { text: 'APAC', value: 'https://api-beautyhub-apac.loreal.io/bhub/products/' },
  { text: 'AMAS', value: 'https://api-beautyhub-amas.loreal.io/bhub/products/' },
  { text: 'CHINA', value: 'https://api-beautyhub.lorealchina.com/bhub/private/products/' },
  { text: 'STAGING', value: 'https://api-beautyhub-emea-staging.loreal.io/bhub/products/' },
];

export const DEFAULT_PRODUCT_API_URL = PRODUCT_API_URLS.filter(config => config.text === 'STAGING')[0].value;

export const PRODUCT_API_VALUES = PRODUCT_API_URLS.map(config => config.value);
