import mapValues from 'lodash/fp/mapValues'
import db from '@/firebase/firestore'
import {
  deepMerge,
  getDocumentsInCollection,
  getDocumentData,
  loopThroughObject,
  updateRef,
  updateActive,
} from '@/service/FirebaseService'
import AuditService from '@/service/AuditService'
import Functions from '@/firebase/functions'
import Vue from 'vue'
import { checkDependencies } from '../../utils/FileHelpers'
import { compareValues } from '@/service/UtilsService'
import {
  unsanitizeArray,
  unsanitizeObject,
} from '@/service/SanitizationService'
import { DocumentData } from 'firebase/firestore'
import {
  FireHierarchy,
  FireComponent,
  FireComponentFieldFile,
  FireComponentFieldWithData,
  FireComponentDependency,
  FireComponentFieldRepeater,
  FireComponentField,
  FirecampRouteMeta,
} from '@/models'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { Collections } from './appversions'
import { RESOURCES } from '@/service/ResourceService'
import { createDataloader } from '@/service/DataloaderService'

const auditLog = new AuditService()

export type HistoryItem = {
  active: boolean
  text: string
  value: number
}

export type FileChange = {
  actions: {
    newName: string
    oldName: string
  }[]
}

type FireComponentWithId = FireComponent & { id: string }
export type AppRouteState = {
  activeRoutes: FirecampRouteMeta[]
  currentRoute: {
    id: string | null
    history: HistoryItem[]
    application: string
    firecamp: {
      hierarchy: FireHierarchy
      components: FireComponentWithId[]
    }
    data: {
      appconfigurations: DocumentData
      appcustomisations: DocumentData
      appcontent: DocumentData
      appresources: DocumentData
    }
    fileChanges: FileChange[]
    temporaryFiles: {
      file: File
      id: string
      path: string
      settings: FireComponentFieldFile['settings']
    }[]
    subCollections: string[] | null
  }
  fetchedRoutes: boolean
}

const initialStateCurrentRoute = {
  id: null,
  history: [],
  application: '',
  firecamp: {
    hierarchy: [],
    components: [],
  },
  data: {
    appconfigurations: {},
    appcustomisations: {},
    appcontent: {},
    appresources: {},
  },
  fileChanges: [],
  temporaryFiles: [],
  subCollections: null,
}

const state: AppRouteState = {
  activeRoutes: [],
  currentRoute: JSON.parse(JSON.stringify(initialStateCurrentRoute)),
  fetchedRoutes: false,
}

const mutations: MutationTree<AppRouteState> = {
  setActiveRoutes(state, activeRoutes: AppRouteState['activeRoutes']) {
    state.activeRoutes = activeRoutes
  },

  setFetchedRoutes(state, fetchedRoutes) {
    state.fetchedRoutes = fetchedRoutes
  },

  setCurrentRoute(state, currentRoute: AppRouteState['currentRoute']) {
    Vue.set(state, 'currentRoute', currentRoute)
  },

  setCurrentRouteId(state, routeID: string) {
    state.currentRoute.id = routeID
  },

  setCurrentRouteHistory(state, history: HistoryItem[]) {
    state.currentRoute.history = history
  },

  setCurrentApplication(state, application: string) {
    state.currentRoute.application = application
  },

  setCurrentRouteFirecamp(
    state,
    {
      hierarchy,
      components,
    }: { hierarchy: FireHierarchy; components: FireComponentWithId[] },
  ) {
    if (hierarchy !== undefined) {
      state.currentRoute.firecamp.hierarchy = hierarchy
    }

    if (components !== undefined) {
      state.currentRoute.firecamp.components = components
    }
  },

  setCurrentRouteData(
    state,
    {
      appconfigurations,
      appresources,
      appcontent,
      appcustomisations,
    }: AppRouteState['currentRoute']['data'],
  ) {
    if (appconfigurations !== undefined) {
      state.currentRoute.data.appconfigurations = appconfigurations
    }

    if (appresources !== undefined) {
      state.currentRoute.data.appresources = appresources
    }

    if (appcontent !== undefined) {
      state.currentRoute.data.appcontent = unsanitizeObject(appcontent)
    }

    if (appcustomisations !== undefined) {
      state.currentRoute.data.appcustomisations = {
        ...appcustomisations,
        appSettings: appcustomisations.appSettings
          ? unsanitizeObject(appcustomisations.appSettings)
          : undefined,
        css: appcustomisations.css
          ? unsanitizeObject(appcustomisations.css)
          : undefined,
        repeaterTabsNames: appcustomisations.repeaterTabsNames
          ? mapValues(unsanitizeArray)(appcustomisations.repeaterTabsNames)
          : undefined,
      }
    }
  },

  setCurrentRouteSubCollections(state, subCollections: string[]) {
    state.currentRoute.subCollections = subCollections
  },

  UPDATE_ROUTE_DATA(
    state,
    payload: {
      collection: string
      id: string
      value: any
      hierarchy?: string[]
    },
  ) {
    let pathToUpdate = state.currentRoute.data[payload.collection]

    if (pathToUpdate) {
      if (payload.hierarchy) {
        payload.hierarchy.forEach((level) => {
          if (!pathToUpdate[level]) pathToUpdate[level] = {}
          pathToUpdate = pathToUpdate[level]
        })
      }

      Vue.set(pathToUpdate, payload.id, payload.value)

      if (
        Array.isArray(payload.value) &&
        Array.isArray(pathToUpdate[payload.id])
      ) {
        payload.value.forEach((value, index) => {
          Vue.set(pathToUpdate[payload.id], index, value)
        })
      }
    }
  },

  // FILES

  addNewFileToUpload(
    state,
    payload: AppRouteState['currentRoute']['temporaryFiles'][0],
  ) {
    state.currentRoute.temporaryFiles =
      state.currentRoute.temporaryFiles.filter((file) => file.id !== payload.id)
    state.currentRoute.temporaryFiles.push(payload)
  },

  removeFileToUpload(state, fileId: string) {
    state.currentRoute.temporaryFiles =
      state.currentRoute.temporaryFiles.filter((file) => fileId !== file.id)
  },

  resetFilesToUpload(state) {
    state.currentRoute.temporaryFiles = []
  },

  REMOVE_ROUTE_ENTRY(
    state,
    payload: { collection: string; hierarchy: string[]; id: string },
  ) {
    let pathToUpdate = state.currentRoute.data[payload.collection]

    if (pathToUpdate) {
      if (payload.hierarchy) {
        payload.hierarchy.forEach((level) => {
          if (!pathToUpdate[level]) pathToUpdate[level] = {}
          pathToUpdate = pathToUpdate[level]
        })
      }

      delete pathToUpdate[payload.id]
    }
  },

  addFileChange(
    state,
    payload: AppRouteState['currentRoute']['fileChanges'][0],
  ) {
    state.currentRoute.fileChanges.push(payload)
  },

  resetFileChanges(state) {
    state.currentRoute.fileChanges = []
  },
}

const getRoutesScopedDataLoader = createDataloader<string, Record<string, any>>(
  async () => [await Functions.getRoutesScoped()],
)

const actions: ActionTree<AppRouteState, RootState> = {
  async bindAppRoutes({ commit, dispatch, state, rootState }) {
    if (state.fetchedRoutes) return state.activeRoutes

    const [{ routes, success }] = await Promise.all([
      getRoutesScopedDataLoader.load('all'),
      dispatch('loadResources', {
        resourceNames: [
          RESOURCES.TOUCHPOINTS,
          RESOURCES.TYPES,
          RESOURCES.ENVIRONMENTS,
          RESOURCES.TENANTS,
          RESOURCES.COUNTRIES,
          RESOURCES.CUSTOMERS,
          RESOURCES.APPLICATIONS,
          RESOURCES.APP_VERSIONS,
        ],
      }),
    ])
    if (!success) throw new Error('Could not get routes')

    const routesFormatted = routes.map((route) => {
      const findData = (
        id: string,
        keyStore: string,
        states: any = rootState,
      ) => {
        return states[keyStore].find((item: any) => item.id === id)
      }

      return {
        id: route.id,
        meta: route.meta,
        application: findData(route.application, 'applications'),
        country: findData(route.country, 'countries'),
        customer: findData(route.customer, 'customers'),
        environment: findData(route.environment, 'envs'),
        tenant: findData(route.tenant, 'tenants'),
        touchpoint: findData(route.touchpoint, 'touchpoints'),
        appversion: findData(
          route.version,
          'activeVersions',
          rootState['appversions'],
        ),
        type: findData(route.type, 'types'),
      }
    })

    commit('setActiveRoutes', routesFormatted)
    commit('setFetchedRoutes', true)

    return routesFormatted
  },

  unbindAppRoutes({ commit }) {
    getRoutesScopedDataLoader.clearAll()
    commit('setActiveRoutes', [])
    commit('setFetchedRoutes', false)
  },

  async deleteRoute({ dispatch }, routeId: string) {
    await updateActive('approutes/' + routeId, false)
    auditLog.delete(`approutes/${routeId}`)
    await dispatch('bindAppRoutes')
  },

  async bindCurrentApp(
    { dispatch, commit, state },
    { routeName, app }: { routeName: string; app: FirecampRouteMeta },
  ) {
    if (!app) throw new Error('Missing app to bind current app')
    try {
      commit('setCurrentRouteSubCollections', app?.meta?.subcollections)
      const tasks = []
      if (app?.appversion) {
        const bindAppData = async () => {
          const currentRouteId = state?.currentRoute?.id
          if (!currentRouteId) throw new Error('Current Route ID is missing.')
          const appRouteData = await getDocumentData(
            `approutes/${currentRouteId}`,
          )
          await dispatch('bindComponents', appRouteData)
          await dispatch('bindHierarchy', appRouteData)
        }
        tasks.push(bindAppData())
      }
      tasks.push(dispatch('bindData', routeName))
      tasks.push(dispatch('getCriterias', state?.currentRoute?.id))

      await Promise.all(tasks)
    } catch (error) {
      throw new Error(error?.message ?? error)
    }
  },

  async bindData({ state, commit, dispatch }, routeName: string) {
    const currentRouteId = state?.currentRoute?.id
    if (!currentRouteId) throw new Error('Current Route ID is missing.')

    const isPrescription = routeName.includes('prescription')
    const hasPrescription =
      isPrescription &&
      state.currentRoute.subCollections.includes('appprescriptions')
    try {
      const [appconfigurations, appcustomisations, appcontent, appresources] =
        await Promise.all([
          getDocumentsInCollection(
            `approutes/${currentRouteId}/appconfigurations`,
            true,
          ),
          getDocumentsInCollection(
            `approutes/${currentRouteId}/appcustomisations`,
            true,
          ),
          getDocumentsInCollection(
            `approutes/${currentRouteId}/appcontent`,
            true,
          ),
          getDocumentsInCollection(
            `approutes/${currentRouteId}/appresources`,
            true,
          ),
          hasPrescription
            ? dispatch('setupActivePrescription', { currentRouteId }).then(
                () => {
                  return Promise.all([
                    dispatch('getBrands'),
                    dispatch('getCriterias'),
                    dispatch('getFlags'),
                  ])
                },
              )
            : null,
        ])

      commit('setCurrentRouteData', {
        appconfigurations: appconfigurations[0],
        appresources: appresources[0],
        appcustomisations: appcustomisations[0],
        appcontent: appcontent[0],
      })
    } catch (error) {
      throw new Error(error?.message ?? error)
    }
  },

  async bindHierarchy({ commit }, appRouteData: FirecampRouteMeta) {
    try {
      const appVersionId = appRouteData.appversion.id
      const hierarchy = await getDocumentsInCollection(
        `appversions/${appVersionId}/firehierarchy`,
        true,
      )
      commit('setCurrentRouteFirecamp', { hierarchy })
    } catch (error) {
      throw new Error(error?.message ?? error)
    }
  },

  async bindComponents({ commit, state }, appRouteData: FirecampRouteMeta) {
    const appVersionId = appRouteData.appversion.id
    const components = await getDocumentsInCollection(
      `appversions/${appVersionId}/firecomponents`,
    )

    const productApiRouteIndex = components.findIndex((c) =>
      ['product-api', 'product.api', 'productapi'].includes(
        c?.id?.toLowerCase(),
      ),
    )

    const productInstanceId =
      state?.currentRoute?.data?.appconfigurations?.productApi
        ?.catalogConfiguration?.productInstanceId
    const isProductApiV3 =
      state?.currentRoute?.data?.appconfigurations?.productApi?.headers
        ?.api_version === 'product.3.0'

    if (productApiRouteIndex >= 0) {
      components[productApiRouteIndex].fields = [
        {
          settings: {
            type: 'separator',
          },
          id: 'productapinew_separator1',
          label: 'Configure catalog',
        },
        {
          id: 'productInstanceId',
          settings: {
            hierarchy: ['productApi', 'catalogConfiguration'],
            type: 'catalogConfigurator',
            collection: 'appconfigurations',
            excludeFromDeploy: true,
          },
        },
        ...(productInstanceId && isProductApiV3
          ? []
          : components[productApiRouteIndex].fields),
      ]
    }

    components.forEach(({ fields }) => {
      fields.forEach((field) => {
        if (field.settings?.type === 'events') {
          const settings = {
            type: 'input',
            maxChar: field.settings.maxChar,
            collection: field.settings.collection,
            hierarchy: field.settings.hierarchy,
            required: true,
          }

          const stepLabelField = {
            id: 'step-{stepId}_label',
            label: 'Step label',
            description: '',
            placeholder: '{stepId}',
            settings,
          }

          if (!field.stepFields) field.stepFields = []
          field.stepFields.unshift(stepLabelField)

          const eventLabelField = {
            id: 'event-{eventId}_label',
            label: 'Event label',
            description: '',
            placeholder: '{eventId}',
            settings,
          }

          if (!field.eventFields) field.eventFields = []
          field.eventFields.unshift(eventLabelField)
        }
      })
    })

    commit('setCurrentRouteFirecamp', { components })
  },

  async setCurrentRouteById(
    { commit, dispatch },
    {
      currentRouteId,
      routeName,
    }: { currentRouteId: string; routeName: string },
  ) {
    if (!currentRouteId) throw new Error('Missing route id.')

    commit('setCurrentRouteId', currentRouteId)
    await dispatch('bindHistory', currentRouteId)
    const appRoute = db.collection('approutes').doc(currentRouteId)
    const appRouteSnapshot = await appRoute.get()
    commit('setCurrentApplication', appRouteSnapshot?.data()?.application?.path)
    await dispatch('bindCurrentApp', {
      routeName,
      app: {
        id: appRouteSnapshot.id,
        ...appRouteSnapshot?.data(),
      },
    })
    commit('appRouteLoaded', true)
  },

  async bindHistory({ commit }, currentRouteId: string) {
    const querySnapshot = await db
      .collection('approutes/' + currentRouteId + '/appconfigurations')
      .where('meta.editVersion', '>', 0)
      .get()
    const history = querySnapshot.docs
      .map((doc) => {
        const meta = doc.data().meta
        return {
          value: meta.editVersion,
          text: meta.createdAt.toDate().toLocaleString(),
          active: meta.active,
        }
      })
      .filter(({ active }) => !active)

    commit('setCurrentRouteHistory', history)
  },

  unsetCurrentRoute({ commit }) {
    commit(
      'setCurrentRoute',
      JSON.parse(JSON.stringify(initialStateCurrentRoute)),
    )
    commit('appRouteLoaded', false)
  },

  async setBackupEditApp({ state, dispatch }, option: { value: number }) {
    const version = option.value
    await Promise.all(
      ['appconfigurations', 'appcustomisations', 'appcontent'].map(
        async (subCollections) => {
          const actual = await db
            .collection(
              'approutes/' + state.currentRoute.id + '/' + subCollections,
            )
            .where('meta.active', '==', true)
            .get()
          const backupDoc = await db
            .collection(
              'approutes/' + state.currentRoute.id + '/' + subCollections,
            )
            .where('meta.editVersion', '==', version)
            .get()
          if (!actual.empty && !backupDoc.empty) {
            await Promise.all([
              updateRef(actual.docs[0].ref.path, { meta: { active: false } }),
              updateRef(backupDoc.docs[0].ref.path, { meta: { active: true } }),
            ])
          }
        },
      ),
    )
    await dispatch('bindData')
    await dispatch('bindHistory', state.currentRoute.id)
  },

  updateRouteData(
    { commit },
    payload: {
      collection?: string
      hierarchy?: string[]
      id?: string
      value: any
    },
  ) {
    if (payload && payload.collection && payload.id) {
      commit('UPDATE_ROUTE_DATA', payload)
    }
  },

  removeRouteEntry(
    { commit },
    payload: { collection?: string; hierarchy?: string[]; id?: string },
  ) {
    if (payload && payload.collection && payload.id) {
      commit('REMOVE_ROUTE_ENTRY', payload)
    }
  },

  async saveDoc(
    { commit, dispatch },
    {
      id,
      collections,
      fileChanges,
    }: { id: string; collections: Collections; fileChanges: FileChange[] },
  ) {
    await Functions.saveDoc(id, { collections, fileChanges })

    commit('resetFileChanges')
    await dispatch('bindHistory', id)
  },

  editFileToUpload(
    { commit },
    payload: { id: string; error: { hasError: boolean; message: string } },
  ) {
    const fileToEdit = state.currentRoute.temporaryFiles.find(
      (file) => payload.id === file.id,
    )
    const editedFile = { ...fileToEdit, ...payload }
    commit('removeFileToUpload', fileToEdit.id)
    commit('addNewFileToUpload', editedFile)
  },

  addFileChangeAction(
    { state, commit },
    payload: { oldName: string; newName: string },
  ) {
    const actionLinked = state.currentRoute.fileChanges.find(
      (change) =>
        change.actions.filter((action) => action.oldName === payload.newName)
          .length > 0,
    )

    if (actionLinked) {
      actionLinked.actions.push(payload)
    } else {
      commit('addFileChange', {
        actions: [payload],
      })
    }
  },

  async compareAndUpdateDoc(
    { dispatch },
    {
      collectionId,
      routeId,
      dataToCompare,
      simulate,
    }: {
      collectionId: string
      routeId: string
      dataToCompare: any
      simulate: boolean
    },
  ) {
    let dataToAdd = {}

    const feedback = {}

    const routeDetails = {
      id: routeId,
      collections: {},
    }

    if (collectionId === 'appprescriptions') {
      feedback[collectionId] = 'No change were made in this collection.'

      return feedback
    }

    const collections = await db
      .collection('approutes')
      .doc(routeId)
      .collection(collectionId)
      .where('meta.active', '==', true)
      .get()

    for await (const col of collections.docs) {
      dataToAdd = await loopThroughObject(col.data(), dataToCompare)

      routeDetails.collections[collectionId] = deepMerge(col.data(), dataToAdd)
    }

    if (Object.keys(dataToAdd).length !== 0) {
      if (!simulate) await dispatch('saveDoc', routeDetails)

      feedback[collectionId] = dataToAdd
    } else {
      feedback[collectionId] = 'No change were made in this collection.'
    }

    return feedback
  },

  updateRepeaterTabsNames(
    { commit },
    {
      field,
      repeaterTabsName,
    }: { field: FireComponentFieldWithData; repeaterTabsName: string[] },
  ) {
    commit('UPDATE_ROUTE_DATA', {
      collection: field.settings.collection,
      hierarchy: ['repeaterTabsNames'],
      value: repeaterTabsName,
      id: field.id,
    })
  },
}

const getters: GetterTree<AppRouteState, RootState> = {
  currentApplication: (state) => {
    const applicationId = state.currentRoute.application
    if (!applicationId) return

    const activeRoute = state.activeRoutes.find(
      (route) => `applications/${route.application?.id}` === applicationId,
    )
    return activeRoute?.application
  },

  listCustomerApps: (state) => (customerName: string) => {
    return (
      state?.activeRoutes?.filter(
        (route) => route?.customer?.displayName === customerName,
      ) ?? []
    )
  },

  listRoutes:
    (state) => (appID: string, appVersion: string, envs: string[]) => {
      return state.activeRoutes.filter(
        (route) =>
          route.application &&
          route.application.id == appID &&
          route.appversion &&
          route.appversion.id == appVersion &&
          (!envs || envs.includes(route.environment.id)),
      )
    },

  getAppRoute: (state) => (appID: string) => {
    return state?.activeRoutes?.length > 0
      ? state?.activeRoutes.find((route) => route.id == appID)
      : null
  },

  // Get route's component
  getComponent: (state) => (componentId: string) => {
    let component

    // Get component
    state.currentRoute.firecamp.components.forEach((comp) => {
      if (comp.id == componentId) component = comp
    })

    return component
  },

  checkDependencies:
    (state) => (dependenciesToCheck: FireComponentDependency) => {
      if (!dependenciesToCheck) return true

      return checkDependencies(dependenciesToCheck, state?.currentRoute?.data)
    },

  getDependentFields: (state) => (fieldToCheck: FireComponentField) => {
    const dependentFields = []

    const fieldComponent = state?.currentRoute?.firecamp?.components?.filter(
      (component) => component?.fields?.includes(fieldToCheck),
    )?.[0]

    fieldComponent?.fields?.forEach((field) => {
      const dependencies = field.settings?.dependencies
      if (!dependencies) return

      dependencies.forEach((dependency) => {
        if (dependency.id === fieldToCheck?.id) dependentFields.push(field)
      })
    })

    return dependentFields
  },

  filesToUpload: (state) => {
    return state.currentRoute.temporaryFiles
  },

  getFieldById: (state) => (id) => {
    return state.currentRoute.firecamp.components
      .flatMap((component) => component.fields)
      .find((field) => field.id == id)
  },

  getFieldValueByPath: (state) => (field: FireComponentFieldWithData) => {
    let value
    let pathToGet = state.currentRoute.data[field.settings.collection]

    if (pathToGet) {
      if (field.settings.hierarchy) {
        field.settings.hierarchy.forEach((level) => {
          if (!pathToGet[level]) pathToGet[level] = {}

          pathToGet = pathToGet[level]
        })
      }

      value = pathToGet[field.id]

      if (
        field.settings.type == 'code' &&
        typeof value != 'undefined' &&
        value != ''
      ) {
        return typeof value === 'string'
          ? value
          : JSON.stringify(value, undefined, 4)
      }
    }

    return value
  },

  getRepeaterTabsName: (state) => (field: FireComponentFieldRepeater) => {
    const pathToGet = state.currentRoute.data[field.settings.collection]
    if (!pathToGet.repeaterTabsNames) return []
    return pathToGet.repeaterTabsNames[field.id] ?? []
  },

  /**
   * Get an element of the configurations array.
   *
   * @param {String} configKey - The configuration key to retrieve.
   * @return {Any} Return the value associated to the configuration key.
   */
  getConfigurationInformations: (state, getters) => (configKey: string) => {
    if (state.currentRoute.data.appconfigurations.length === 0)
      throw new Error('There is no configurations available.')
    if (
      !Object.prototype.hasOwnProperty.call(
        state.currentRoute.data.appconfigurations,
        configKey,
      )
    ) {
      console.warn(`The (${configKey}) configuration key does not exists.`)
      return null
    }
    return JSON.parse(
      JSON.stringify({
        ...state.currentRoute.data.appconfigurations[configKey],
        ...(configKey === 'productApi' && {
          service:
            getters.getAppRoute(state.currentRoute.id)?.application?.id ??
            'default',
        }),
      }),
    )
  },

  getCountriesByCustomers:
    (state) => (customer: FirecampRouteMeta['customer']) => {
      if (!customer) return []
      return state.activeRoutes
        .reduce(
          (acc, route) => {
            if (
              route.customer?.id === customer.id &&
              !acc.ids.has(route.country?.id)
            ) {
              acc.ids.add(route.country.id)
              acc.countries.push(route.country)
            }
            return acc
          },
          {
            ids: new Set(),
            countries: [],
          },
        )
        .countries?.sort(compareValues('displayName'))
    },

  getAppRoutesByAppVersion: (state) => (appVersionId: string) => {
    if (!appVersionId) return []
    return state.activeRoutes.filter(
      (route) => route?.appversion?.id === appVersionId,
    )
  },

  getAppRoutesByCustomerCountryEnvAppIds:
    (state) =>
    (customerId: string, countryId: string, envId: string, appId: string) => {
      if (!customerId || !countryId || !envId || !appId) return []
      return state.activeRoutes.filter(
        (route) =>
          route?.customer?.id === customerId &&
          route?.country?.id === countryId &&
          route?.environment?.id === envId &&
          route?.application?.id === appId,
      )
    },

  getFileChanges: (state) => {
    return state.currentRoute.fileChanges
  },

  getApplication: (state, _getters, rootState) => {
    return rootState.applications.find(
      (app) => `applications/${app.id}` === state.currentRoute.application,
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
