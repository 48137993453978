import { DocumentData } from 'firebase/firestore'
import firebase from 'firebase/compat/app'
import auth from '@/firebase/auth'
import db from '@/firebase/firestore'
import Functions from '@/firebase/functions'
import { DocumentMeta, Scope } from '@/models'
import AuditService from '@/service/AuditService'
import { updateVersion } from '@/utils/StringHelpers'

export const auditLog = new AuditService()

export const database: firebase.firestore.Firestore = db

export const getNewServerTimestamp =
  firebase.firestore.FieldValue.serverTimestamp()

export const getMetaData: DocumentMeta = {
  active: true,
  version: 1,
  createdAt: getNewServerTimestamp,
  updatedAt: getNewServerTimestamp,
}

/**
 * Get the document reference for a specific document path.
 *
 * @async
 * @param {String} docPath - The document path.
 * @return {Promise<Object>} The document reference.
 */
export function getDocumentReference(
  docPath: string,
): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
  if (!docPath) throw new Error('Missing document path.')
  return db.doc(docPath)
}

/**
 * Get the document snapshot.
 *
 * @async
 * @param {String} docPath - The document path.
 * @return {Promise<Object>} The document snapshot.
 */
export async function getDocumentSnapshot(
  docPath: string,
): Promise<firebase.firestore.DocumentSnapshot> {
  if (!docPath) throw new Error('Missing document path.')
  return await db.doc(docPath).get()
}

/**
 * Get the collection reference for a specific collection path.
 *
 * @async
 * @param {String} collectionPath - The collection path.
 * @return {Promise<Object>} The collection reference.
 */
export function getCollectionReference(
  collectionPath: string,
): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  if (!collectionPath) throw new Error('Missing collection path.')
  return db.collection(collectionPath)
}

/**
 * Get the collection snapshot.
 *
 * @async
 * @param {String} collectionPath - The collection path.
 * @return {Promise<Object>} The collection snapshot.
 */
export async function getCollectionSnapshot(
  collectionPath: string,
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  if (!collectionPath) throw new Error('Missing collection path.')
  return await db.collection(collectionPath).get()
}

/**
 * Get the document data.
 *
 * @async
 * @param {String} docPath - The document path.
 * @return {Promise<Object>} The document reference.
 */
export async function getDocumentData(
  docPath: string,
): Promise<Record<string, any>> {
  const documentSnapshot = await getDocumentSnapshot(docPath)
  const documentData = {
    id: documentSnapshot.id,
    ...documentSnapshot.data(),
  }

  await Promise.all(
    Object.keys(documentData).map(async (field) => {
      // When the field is reference we get the data.
      if (
        ![null, undefined].includes(documentData[field]) ||
        (![null, undefined].includes(field) &&
          (typeof documentData[field] === 'object' ||
            Array.isArray(documentData[field])))
      ) {
        documentData[field] = await getDocumentDataRecursive(
          documentData[field],
        )
      }
    }),
  )

  return documentSnapshot.exists ? documentData : {}
}

/**
 * Get the document data recursively.
 *
 * @async
 * @param {Object|Array} field - The field to parse.
 * @return {Promise<any>} Returns the data.
 */
export async function getDocumentDataRecursive(
  field:
    | firebase.firestore.DocumentReference
    | Record<string, any>
    | any[]
    | null
    | undefined,
): Promise<any> {
  if (
    [null, undefined].includes(field) ||
    (typeof field !== 'object' && !Array.isArray(field))
  )
    return field

  // Handling Arrays
  if (Array.isArray(field)) {
    const arrayData = await Promise.all(
      field.map(async (data) => await getDocumentDataRecursive(data)),
    )
    return arrayData.flat()
  }

  if (field instanceof firebase.firestore.DocumentReference) {
    return await getDocumentData(field.path)
  }

  const keys = Object.keys(field)
  if (keys?.length > 0) {
    // Check if there is a reference in object
    const referenceKeys = keys.filter(
      (key) => field[key] instanceof firebase.firestore.DocumentReference,
    )

    if (referenceKeys?.length === 0) return field

    const fieldCopy = { ...field }
    return await Promise.all([
      referenceKeys.reduce(async (accumulator, key) => {
        const data = await getDocumentDataRecursive(field[key])
        return {
          ...accumulator,
          [key]: data,
        }
      }, fieldCopy),
    ])
  }

  return field
}

/**
 * Get the first active document of a collection.
 *
 * @async
 * @param {String} collectionPath - The path to the collection.
 * @return {Promise<any>} Returns the data.
 */
export async function getFirstActiveDocument(
  collectionPath: string,
): Promise<
  firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
> {
  if (!collectionPath) throw new Error('Missing collection path.')
  const snap = await db
    .collection(collectionPath)
    .where('meta.active', '==', true)
    .get()

  if (snap.size === 0) {
    throw new Error(`No active document found under ${collectionPath}`)
  }

  return snap.docs[0]
}

/**
 * Get the first active document data.
 *
 * @async
 * @param {String} collectionPath - The path to the collection.
 * @return {Promise<any>} Returns the data.*
 */
export async function getFirstActiveDocumentData(
  collectionPath: string,
): Promise<Record<string, any> | undefined> {
  if (!collectionPath) throw new Error('Missing collection path.')
  const activeDocumentSnapshot = await getFirstActiveDocument(collectionPath)
  const data = activeDocumentSnapshot.data()
  if (!data) {
    return
  }
  return {
    ...activeDocumentSnapshot.data(),
    path: activeDocumentSnapshot.ref.path,
  }
}

export type FirestoreReference =
  | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  | firebase.firestore.Query<firebase.firestore.DocumentData>

/**
 * Get all documents inside a Firestore collection.
 *
 * @async
 * @param {String} collectionPath - The path to the collection.
 * @param {Boolean} getActiveDocuments - Determine if we get the meta.active documents.
 * @param {Object} converter - An object with functions that allows Firebase to get and set documents according to a certain formatting.
 * @return {Promise<Array>} Returns all collection's documents.
 */
export async function getDocumentsInCollection(
  collectionPath: string,
  getActiveDocuments = false,
  converter: any = null,
  scope: Scope = null,
  customFilter: (ref: FirestoreReference) => FirestoreReference = null,
): Promise<Record<string, any>[]> {
  if (!collectionPath) throw new Error('Missing collection path.')
  const documents = []

  let collectionRef: FirestoreReference = db.collection(collectionPath)
  let snapshotDocs = []
  if (getActiveDocuments) {
    collectionRef = collectionRef.where('meta.active', '==', true)
  }
  if (customFilter) {
    collectionRef = customFilter(collectionRef)
  }
  if (scope) {
    const isAllIncluded = scope?.included?.includes('*')
    const scopeFilter = isAllIncluded ? scope?.excluded : scope?.included

    const chunckSize = 10
    let collectionRefsArray: FirestoreReference[] = []
    if (scopeFilter?.length > chunckSize) {
      for (
        let i = 0;
        i < scopeFilter?.length + (scopeFilter.length % chunckSize);
        i += chunckSize
      ) {
        const chunck = scopeFilter?.slice(i, i + chunckSize)
        // If the scope exluded is > chunckSize the rest should be filtered after
        if (!(isAllIncluded && i > 0) && chunck.length > 0) {
          collectionRefsArray.push(
            collectionRef.where(
              firebase.firestore.FieldPath.documentId(),
              isAllIncluded ? 'not-in' : 'in',
              chunck,
            ),
          )
        }
      }
    } else if (scopeFilter?.length > 0) {
      collectionRefsArray.push(
        collectionRef.where(
          firebase.firestore.FieldPath.documentId(),
          isAllIncluded ? 'not-in' : 'in',
          scopeFilter,
        ),
      )
    } else if (isAllIncluded) {
      collectionRefsArray.push(collectionRef)
    }

    if (converter) {
      collectionRefsArray = collectionRefsArray?.map((ref) =>
        ref.withConverter(converter),
      )
    }

    snapshotDocs = (
      await Promise.all(
        collectionRefsArray?.map(async (ref) => {
          const snap = await ref.get()
          return snap.docs
        }),
      )
    )
      .flat()
      .filter(
        (snap) =>
          !(isAllIncluded && scopeFilter?.length > chunckSize) ||
          !scopeFilter
            ?.slice(chunckSize, scopeFilter?.length)
            .includes(snap.ref.id),
      )
    // If the scope exluded is > chunckSize the rest should be filtered after
  } else if (converter) {
    snapshotDocs =
      (await collectionRef.withConverter(converter).get()).docs ?? []
  } else {
    snapshotDocs = (await collectionRef.get()).docs ?? []
  }

  await Promise.all(
    snapshotDocs.map(async (doc) => {
      const documentData = await getDocumentData(doc.ref.path)
      documents.push(documentData)
    }),
  )
  return documents
}

/**
 * Add a document to a collection.
 *
 * @param {String} docPath - The document reference to add.
 * @param {String} collectionName - The collection name.
 * @param {Object} docToAdd - An object to add to firebase.
 * @param {Object} converter - An object with functions that allows Firebase to get and set documents according to a certain formatting.
 */
export async function addDocToCollection(
  docPath: string = null,
  collectionName: string,
  docToAdd: Record<string, any>,
  converter: any = null,
  docId: string = null,
): Promise<void> {
  if (!collectionName) throw new Error("Missing collection's name.")
  if (!docToAdd) throw new Error('Missing new document to add.')

  const newDocData = { ...docToAdd }

  newDocData.meta = {
    ...getMetaData,
    ...newDocData.meta,
    lastModifiedBy: (await auth.getCurrentUser()).uid,
  }

  let collection = db.collection(collectionName)

  if (docPath) {
    const parentDoc = await db.doc(docPath).get()
    collection = parentDoc.ref.collection(collectionName)
    const docData = parentDoc.data()

    const subcollections =
      (typeof docData?.meta?.subcollections === 'object'
        ? Object.values(docData?.meta?.subcollections)
        : docData?.meta?.subcollections) ?? []

    const doesCollectionExists =
      subcollections.includes(collectionName) ?? false
    if (!doesCollectionExists) subcollections.push(collectionName)

    const docMeta = {
      ...getMetaData,
      ...docData?.meta,
      subcollections,
      updatedAt: getNewServerTimestamp,
      lastModifiedBy: (await auth.getCurrentUser()).uid,
    }

    parentDoc.ref
      .update({
        meta: docMeta,
      })
      .catch(() => {
        throw new Error(`Update failed for document : ${docPath}`)
      })
  }

  let doc = docId ? collection.doc(docId) : addGeneratedDoc(collection.path)
  if (converter) doc = doc.withConverter(converter)
  await doc.set(newDocData).catch(() => {
    throw new Error(`Document addition for reference ${docId} failed`)
  })

  await auditLog.create(doc.path)
}

/**
 * Add a new empty doc to a collection and returns the generatedRef.
 *
 * @param {String} docPath - The document reference to add.
 * @param {String} collectionPath - The collection path.
 */
export function addGeneratedDoc(collectionPath: string = null): DocumentData {
  if (!collectionPath)
    throw new Error('Missing collection path for new document.')

  return db.collection(collectionPath).doc()
}

/**
 * Delete a document.
 *
 * @param {String} docPath - The document reference to delete.
 */
export async function deleteRef(docPath: string): Promise<void> {
  if (!docPath) throw new Error('Missing document reference.')

  let payload = null

  try {
    const docToDelete = await db.doc(docPath).get()
    let parentCollectionLength
    const parentDocument = await docToDelete?.ref?.parent?.parent?.get()
    let parentCollectionRef
    if (parentDocument) {
      parentCollectionRef = docToDelete?.ref?.parent
      const parentCollectionData = await parentCollectionRef?.get()
      parentCollectionLength = parentCollectionData?.size - 1
    }

    payload = await docToDelete.ref.delete()
    await auditLog.delete(docPath)

    if (parentDocument && parentCollectionLength === 0) {
      const parentDocumentData = parentDocument.data()
      const subcollections = parentDocumentData.meta?.subcollections?.filter(
        (collection) => collection !== parentCollectionRef.id,
      )
      const parentDocumentMeta = {
        ...parentDocumentData.meta,
        subcollections,
        updatedAt: getNewServerTimestamp,
        lastModifiedBy: (await auth.getCurrentUser()).uid,
      }

      await updateRef(parentDocument.ref.path, { meta: parentDocumentMeta })
    }
  } catch (error) {
    throw new Error(`Can not delete reference ${docPath}`)
  }

  return payload
}
/**
 * Update fields from document reference.
 *
 * @param {String} docPath - Document reference.
 * @param {Object} fields - An object with the fields an values to update.
 */
export async function updateRef(
  docPath: string,
  fields: firebase.firestore.DocumentData = {},
  override = false,
): Promise<void> {
  if (!docPath) throw new Error('Missing document reference.')
  if (!fields) throw new Error('Missing fields to update.')

  let payload = null

  try {
    const docToUpdate = await db.doc(docPath).get()
    const docData = docToUpdate.data()
    const newVersion =
      fields.meta?.version ?? updateVersion(docData?.meta?.version ?? 0)
    const docMeta = {
      ...docData?.meta,
      ...(!!fields.meta && fields.meta),
      ...(docData?.meta?.active !== false &&
        fields?.meta?.active !== false && {
          active: true,
        }),
      ...(!docData?.meta?.createdAt &&
        !fields.meta?.createdAt && { createdAt: getNewServerTimestamp }),
      lastModifiedBy: (await auth.getCurrentUser()).uid,
      updatedAt: getNewServerTimestamp,
      version: newVersion,
    }
    // Delete meta from field if already to avoid override.
    delete fields.meta

    const data = {
      meta: docMeta,
      ...(fields && fields),
    }

    if (override) {
      payload = await docToUpdate.ref.set(data)
    } else {
      payload = await docToUpdate.ref.update(data)
    }

    await auditLog.update(docPath)
  } catch (error) {
    throw new Error(`Update failed for document : ${docPath}`)
  }

  return payload
}

/**
 * Update the active field in the meta object in Firebase.
 *
 * @async
 * @param {String} docPath - The document path in Firebase.
 * @param {Boolean} isActive - The active value to update.
 */
export async function updateActive(
  docPath: string,
  isActive: boolean,
): Promise<void> {
  if (!docPath) throw new Error('Missing document reference.')
  if (isActive === null || isActive === undefined)
    throw new Error('Missing active value to update.')

  let payload = null
  const docToUpdate = await db.doc(docPath).get()
  const docData = docToUpdate.data()
  const docMeta = {
    ...docData?.meta,
    active: isActive,
    updatedAt: getNewServerTimestamp,
    lastModifiedBy: (await auth.getCurrentUser()).uid,
  }

  try {
    payload = docToUpdate.ref.update({
      meta: docMeta,
    })

    await auditLog.update(docPath)
  } catch (error) {
    throw new Error(`Update failed for document: ${docPath}`)
  }

  return payload
}

/**
 * Delete a field for a giver reference.
 *
 * @param {String} docPath - The document reference.
 * @param {String} fieldName - The field name to delete.
 */
export async function deleteField(
  docPath: string,
  fieldName: string,
): Promise<void> {
  if (!docPath) throw new Error('Missing document reference.')
  if (!fieldName) throw new Error('Missing fields to delete.')

  let payload = null

  try {
    payload = await db.doc(docPath).update({
      [fieldName]: firebase.firestore.FieldValue.delete(),
    })

    await auditLog.update(docPath)
  } catch (error) {
    throw new Error(`Update failed for document : ${docPath}`)
  }

  return payload
}

/**
 * Uploads a profile picture for the defined user in the using the cloud function uploadImage.
 * Return the path to the picture in the storage.
 *
 * @async
 * @function uploadUserProfilePicture
 * @param {String} userId - The id of the user.
 * @param {Object} file - The image to upload.
 * @returns {String} - The path of the picture on the storage.
 */
export async function uploadUserProfilePicture(
  userId: string,
  file: File,
): Promise<string> {
  if (userId?.length === 0 || !file) return ''

  const userPath = `users/${userId}`
  try {
    const fileExtension = file?.name?.split('.')?.pop()
    const imagePath = `${userPath}/profile_picture.${fileExtension}`
    const uploadImageResponse = (await Functions.uploadImage(
      imagePath,
      file,
    )) as unknown as { path: string }
    return uploadImageResponse.path
  } catch (error) {
    throw new Error(`Upload of profile picture failed for user : ${userId}`)
  }
}

// Function to check if doc already exists
export const doesDocExist = async (collectionRef: string, docName: string) => {
  return (await db.collection(collectionRef).doc(docName).get()).exists
}

// Function to delete documents from collections
export const deleteSubcollectionsDocs = (
  docRef: firebase.firestore.DocumentReference,
  collectionsList: string[],
) => {
  if (collectionsList.length) {
    collectionsList.forEach((collection) => {
      docRef
        .collection(collection)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            // The document has subcollections, lets delete them too!
            if (
              typeof doc.data().meta != 'undefined' &&
              typeof doc.data().meta.subcollections != 'undefined' &&
              doc.data().meta.subcollections.length
            ) {
              deleteSubcollectionsDocs(doc.ref, doc.data().meta.subcollections)
            }

            await doc.ref.delete()
            await auditLog.delete(doc.ref.path)
          })
        })
    })
  }
}

// Loop through object to check each entry
export const loopThroughObject = (existingColData, newJsonData) => {
  const dataToAdd = {}

  // Explore new JSON, add missing entries in current collection.
  for (const dataEntry of Object.keys(newJsonData)) {
    // If item is a parent, explore it, else check if value exists in DB.
    if (
      typeof newJsonData[dataEntry] == 'object' &&
      !Array.isArray(newJsonData[dataEntry])
    ) {
      if (typeof existingColData[dataEntry] == 'undefined')
        existingColData[dataEntry] = {}
      const objectChild = loopThroughObject(
        existingColData[dataEntry],
        newJsonData[dataEntry],
      )

      if (Object.keys(objectChild).length !== 0) {
        dataToAdd[dataEntry] = objectChild
      }
    } else {
      if (typeof existingColData[dataEntry] == 'undefined') {
        dataToAdd[dataEntry] = newJsonData[dataEntry]
      }
    }
  }

  return dataToAdd
}

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const deepMerge = (...objects) => {
  const isObject = (obj) => obj && typeof obj === 'object'

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key]
      const oVal = obj[key]

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal)
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = deepMerge(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })

    return prev
  }, {})
}

export function getCounterCollectionName(name: string) {
  return `counters_${name}`
}
