
  import ProductAPIConfigurationSelectTenant from '@/components/product-api-config/ProductAPIConfigurationSelectTenant.vue'
  import TitleContainer from '@/components/containers/TitleContainer.vue'
  import Multiselect from '@/components/form/Multiselect.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import List from '@/components/list/List.vue'
  import Tile from '@/components/tile/Tile.vue'
  import Functions, { CatalogItem } from '@/firebase/functions'
  import groupBy from 'lodash/fp/groupBy'
  import { RESOURCES } from '@/service/ResourceService'
  import FirecampLoader from '@/components/loader/FirecampLoader.vue'

  const reduceDisplayName = (
    elements: { id: string; displayName: string }[],
  ): Record<string, string> => {
    if (!elements) return {}

    return elements.reduce((indexed, { id, displayName }) => {
      indexed[id] = displayName
      return indexed
    }, {})
  }

  export default {
    name: 'CatalogConfigurationList',
    components: {
      ProductAPIConfigurationSelectTenant,
      TitleContainer,
      Multiselect,
      List,
      Tile,
      FirecampLoader,
    },
    props: {
      listEmpty: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoading: false,
        country: null,
        customer: null,
        environment: null,
        environments: [
          {
            displayName: 'Staging',
            id: 'staging',
          },
          {
            displayName: 'Production',
            id: 'production',
          },
        ],
        dataLoaded: false,
        searchInput: '',
        catalogList: [] as CatalogItem[],
        additionalActions: true,
        tenant: this.$route.params.tenant,
      }
    },
    computed: {
      ...mapState({
        customers: (state: { customers: any }) => state.customers,
        countries: (state: { bmdmCountries: any }) => state.bmdmCountries,
        fetchedResources: (state) => state.fetchedResources,
        appRouteID: (state) => state.approutes.currentRoute.id,
      }),
      ...mapGetters('scopes', ['computedScope']),
      title() {
        return this.$route.meta?.displayName ?? 'Catalog Configuration'
      },
      customerDisplayName() {
        return reduceDisplayName(this.customers)
      },
      groupByCustomerCode() {
        return !this.dataLoaded
          ? {}
          : groupBy('customerCode')(this.filteredCatalogList)
      },
      filteredCatalogList() {
        if (!this.dataLoaded) return []

        const filteredList = []

        for (let i = 0; i < this.catalogList.length; i++) {
          const catalog = this.catalogList[i]
          if (
            (!this.customer &&
              !this.country &&
              !this.environment &&
              !this.searchInput) ||
            ((this.customer
              ? this.customer.id === catalog.customerCode
              : true) &&
              (this.country
                ? this.country.id.toLowerCase() === catalog.countryCode
                : true) &&
              (this.environment
                ? this.environment.id === catalog.environment
                : true) &&
              (this.searchInput
                ? catalog.name
                    .toLowerCase()
                    .includes(this.searchInput.toLowerCase())
                : true))
          ) {
            filteredList.push(catalog)
          }
        }
        return filteredList
      },
    },
    async mounted() {
      await this.loadData()
    },
    watch: {
      '$route.params.tenant'() {
        this.loadCatalog()
      },
    },
    methods: {
      ...mapActions(['loadBmdmCountries', 'loadResources', 'bindAppRoutes']),
      async loadCatalog() {
        this.catalogList = [] as CatalogItem[]
        const tenant = this.$route.params.tenant

        if (!tenant && this.computedScope.tenants.options.length > 0) {
          this.$router.push({
            name: this.$route.name,
            params: {
              ...this.$route.params,
              tenant: this.computedScope.tenants.options[0].id,
            },
          })
          return
        }

        if (
          !this.computedScope.tenants.options
            .map((tenant) => tenant.id)
            .includes(tenant)
        ) {
          return
        }

        try {
          this.appLoading(true)
          const customerIds = this.customers.map((customer) => customer.id)
          this.catalogList = (await Functions.getCatalogs(
            this.searchInput,
            tenant,
            customerIds,
          )) as CatalogItem[]

          this.catalogList = this.catalogList
            .filter((catalog) => customerIds.includes(catalog.customerCode))
            .sort((a, b) => a.name.localeCompare(b.name))
          this.dataLoaded = true
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.appLoading(false)
        }
      },

      clearFilters() {
        this.customer = null
        this.country = null
        this.environment = null
        this.searchInput = ''
      },

      async loadData() {
        try {
          this.appLoading(true)
          await Promise.all([
            this.loadResources({
              resourceNames: [RESOURCES.CUSTOMERS],
            }),
            this.loadBmdmCountries(),
            this.bindAppRoutes(),
          ])
          await this.loadCatalog()
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
