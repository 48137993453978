<template>
  <b-modal
    id="modal-add-skus"
    ref="modal"
    title="Add SKU(s) to the instance and catalog"
    size="lg"
    v-on="$listeners"
    :ok-disabled="showWarningMessage || count === 0"
    @cancel.prevent="handleCloseModal"
    @hide="handleCloseModal"
    @close.prevent="handleCloseModal"
    :visible="visible"
  >
    <b-form ref="form">
      <div>
        <p>Copy paste below one SKU or a list of SKUs separated with ";"</p>
        <p
          class="text-muted"
          small
        ></p>
        <p>
          <MultiSelect
            @tagsAdded="handleTags"
            @disabledTagsAdd="handleDisableAdd"
          />
        </p>
        <p>{{ count }} SKU{{ count > 1 ? `s` : '' }}.</p>
        <p
          v-if="showWarningMessage"
          class="alert alert-warning"
          role="alert"
        >
          {{ warningMessage }}
        </p>
      </div>
    </b-form>
    <template #modal-footer>
      <b-button
        variant="secondary"
        @click="handleCloseModal"
      >
        Cancel
      </b-button>
      <SubmitButton
        class="mt-2"
        :disabled="showWarningMessage || count === 0"
        :label="'Send'"
        @click="handleOk"
      />
    </template>
  </b-modal>
</template>

<script>
  import MultiSelect from './AddSkuMultiSelect.vue'
  import Functions from '@/firebase/functions'
  import SubmitButton from '@/components/form/SubmitButton'
  import { mapActions } from 'vuex'

  export default {
    name: 'AddSkusModal',

    components: {
      MultiSelect,
      SubmitButton,
    },

    emits: ['cancel', 'skusSent', 'error', 'skuModalClosed'],

    props: {
      visible: Boolean,
      catalogConnectorId: {
        type: String,
        required: true,
      },
      tenant: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        inputConfirmation: '',
        tags: [],
        count: 0,
        showWarningMessage: false,
        warningMessage: 'Please check your SKUs',
      }
    },

    computed: {},

    methods: {
      ...mapActions('products', ['loadCatalogConnector']),
      resetModal() {
        this.inputConfirmation = ''
      },
      handleTags(tags) {
        this.tags = tags
        this.count = tags.length
      },
      handleDisableAdd(value) {
        this.showWarningMessage = value
      },
      handleCloseModal() {
        this.tags = []
        this.count = 0
        this.$emit('cancel')
        this.$nextTick(() => {
          this.$bvModal.hide(`modal-add-skus`)
        })
      },
      async handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()

        const tagsNames = this.tags.map((tag) => tag.name)

        const res = await Functions.addListOfSkus(
          this.tenant,
          this.catalogConnectorId,
          {
            skus: tagsNames,
          },
        )
        await this.loadCatalogConnector({
          catalogConnectorId: this.catalogConnectorId,
          tenant: this.tenant,
        })
        if (res.successes.length) this.$emit('skusSent', res.successes)
        else if (res.errors.length) {
          this.$emit('error', `No SKU has been added`)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .allow-white-spaces {
    white-space: pre-wrap;
    line-height: 1rem;
  }
</style>
