<template>
  <div
    v-if="isDSFScaleService"
    class="col mb-4"
  >
    <p class="product-list-new_empty-catalog-description">
      Please configure your catalog in the Settings section to be able to
      consult your Product List
    </p>
    <div class="d-flex justify-content-end">
      <b-button
        variant="primary"
        @click="redirectToSettings"
      >
        Go to Settings
      </b-button>
    </div>
  </div>
  <div
    v-else
    class="p-2"
  >
    <p class="product-list-new_empty-catalog-description">
      No catalog configured for this instance yet, please ask your scale manager
      to configure it.
    </p>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState({
        appRouteID: (state) => state.approutes.currentRoute.id,
        roleId: (state) => state.user.role.id,
      }),
      isDSFScaleService() {
        return (
          this.roleId == 'dsf_scale_service' ||
          this.roleId == 'portal_administrator'
        )
      },
    },
    methods: {
      redirectToSettings() {
        this.$router.push({
          name: 'edit-app',
          params: { app_id: this.appRouteID },
          hash: '#api',
        })
      },
    },
  }
</script>
