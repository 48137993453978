<template>
  <b-modal
    id="modal-add-products"
    ref="modal"
    scrollable
    title="Add a product to the catalog"
    size="lg"
    v-on="$listeners"
    @hide="handleCloseModal"
    @cancel.prevent="handleCloseModal"
    @close.prevent="handleCloseModal"
    :visible="visible"
  >
    <b-form ref="form">
      <b-form-group
        class="pt-1 pb-3"
        required
        description="Example: 3600523990252"
      >
        <template #label>
          SKU or EAN
          <small class="form-group text-muted">id: sku</small>
        </template>
        <b-form-input
          id="scrollContainer"
          v-model="skuInput"
          type="text"
          :state="isSkuValid && isSkuUnique"
          required
          placeholder="Enter a sku..."
          @update="skuValidation"
          :debounce="500"
        />
        <b-form-invalid-feedback>
          <div v-if="!isSkuUnique">This SKU already exists in the catalog</div>
          <div v-if="!isSkuValid">This SKU contains unvalid characters</div>
        </b-form-invalid-feedback>
      </b-form-group>
      <div v-if="isSkuUnique && isSkuValid">
        <b-form-group
          class="pt-1 pb-3"
          label-for="name"
          description=""
          required
        >
          <template #label>
            Product name displayed in the service
            <small class="form-group text-muted">id: name</small>
          </template>
          <b-form-input
            id="name"
            v-model="nameInput"
            type="text"
            placeholder="Product name"
          />
        </b-form-group>
        <b-form-group
          class="pt-1 pb-3"
          required
        >
          <template #label>
            Brand
            <small class="form-group text-muted">id: brandcode</small>
          </template>
          <Multiselect
            :state="brandInput?.displayName?.length > 0"
            v-model="brandInput"
            :options="loadedResources ? brands : []"
            :loading="!loadedResources"
            label="displayName"
            placeholder="Select a brand"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Packshot URL
            <small class="form-group text-muted">id: packshotImageUrl</small>
          </template>
          <div class="container">
            <div class="row">
              <b-form-input
                :disabled="!isSkuValid"
                class="col col-9"
                v-model="packshotUrlInput"
                type="text"
                placeholder="https://www.example.com/packshot.jpg"
              />
              <PackshotPreview
                :disabled="!isSkuValid"
                class="col col-3"
                :src="packshotUrlInput"
              />
            </div>
          </div>
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            PDP URL
            <small class="form-group text-muted">id: pdpUrl</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="pdpUrlInput"
            type="text"
            placeholder="https://www.example.com/pdp"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Description
            <small class="form-group text-muted">id: shortDescription</small>
          </template>
          <b-form-textarea
            :disabled="!isSkuValid"
            v-model="descriptionInput"
            placeholder="Short description of the product"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Ecommerce: clickToBuyUrl
            <small class="form-group text-muted"
              >id: eCommerce.clickToBuyUrl</small
            >
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="clickToBuyURLInput"
            type="text"
            placeholder="https://www.example.com/buy"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Ecommerce: color
            <small class="form-group text-muted">id: eCommerce.color</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="colorInput"
            type="text"
            placeholder="color"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            HexValue
            <small class="form-group text-muted">id: hexValue</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="hexValueInput"
            type="text"
            placeholder="#000000"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            ShadeImagePath
            <small class="form-group text-muted">id: shadeImagePath</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="shadeImagePathInput"
            type="text"
            placeholder="https://www.example.com/shade.jpg"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Family
            <small class="form-group text-muted">id: family</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="familyInput"
            type="text"
            placeholder="family"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Finish
            <small class="form-group text-muted">id: finish</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="finishInput"
            type="text"
            placeholder="finish"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Shade
            <small class="form-group text-muted">id: shadeName</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="shadeNameInput"
            type="text"
            placeholder="shade"
          />
        </b-form-group>
        <b-form-group class="pt-1 pb-3">
          <template #label>
            Franchise
            <small class="form-group text-muted">id: franchise</small>
          </template>
          <b-form-input
            :disabled="!isSkuValid"
            v-model="franchiseInput"
            type="text"
            placeholder="franchise"
          />
        </b-form-group>
      </div>
    </b-form>
    <template #modal-footer>
      <b-button
        variant="secondary"
        @click="handleCloseModal"
      >
        Cancel
      </b-button>
      <SubmitButton
        class="mt-2"
        :disabled="isSubmitDisabled"
        :label="'Save and close'"
        @click="saveAndClose"
      />

      <SubmitButton
        class="mt-2"
        :class="{ 'button-sent': isSent }"
        :disabled="isSubmitDisabled"
        :loading="isAppLoading"
        :label="submitLabel"
        @click="saveAndAdd"
      />
    </template>
  </b-modal>
</template>

<script>
  import Multiselect from '@/components/form/Multiselect'
  import PackshotPreview from '@/components/media/PackshotPreview.vue'
  import SubmitButton from '@/components/form/SubmitButton'
  import Functions from '@/firebase/functions'
  import { AppMixin } from '@/mixins/AppMixin'
  import { mapActions, mapState } from 'vuex'
  import { RESOURCES } from '@/service/ResourceService'

  const initialData = {
    skuInput: null,
    isSkuValid: null,
    isSkuUnique: null,
    nameInput: null,
    clickToBuyURLInput: null,
    colorInput: null,
    hexValueInput: null,
    finishInput: null,
    familyInput: null,
    franchiseInput: null,
    shadeNameInput: null,
    brandInput: { id: '', displayName: 'Select a brand' },
    packshotUrlInput: '',
    pdpUrlInput: null,
    descriptionInput: null,
    shadeImagePathInput: null,
    submitLabel: 'Save and add another product',
  }

  export default {
    name: 'AddProductsModal',

    components: {
      Multiselect,
      PackshotPreview,
      SubmitButton,
    },

    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      catalogConnectorId: {
        type: String,
        default: '',
      },
      countryCode: {
        type: String,
        default: '',
      },
      tenant: {
        type: String,
        required: true,
      },
    },

    mixins: [AppMixin],

    data() {
      return {
        ...initialData,
        isSent: false,
        loadedResources: false,
      }
    },

    computed: {
      isSubmitDisabled() {
        return !(
          this.skuInput?.length > 0 &&
          this.isSkuValid &&
          this.isSkuUnique &&
          this.nameInput?.length > 0 &&
          this.brandInput?.displayName.length > 0
        )
      },
      //prescriptionDataLoaded: (state) => state.prescriptions.isLoaded,
      ...mapState({
        fetchedResources: (state) => state.fetchedResources,
        brands: (state) => state.brands,
      }),
    },

    async mounted() {
      this.loadedResources = this.fetchedResources.brands

      await this.loadResources({
        resourceNames: [RESOURCES.BRANDS],
      })

      this.loadedResources = true
    },
    methods: {
      ...mapActions(['loadResources', 'products/loadCatalogConnector']),
      resetModal() {
        this.inputConfirmation = ''
      },
      handleDisableAdd(value) {
        this.showWarningMessage = value
      },
      handleCloseModal() {
        this.resetValues()
        this.$emit('cancel')
        this.$nextTick(() => {
          this.$bvModal.hide(`modal-add-products`)
        })
      },
      async saveAndClose(bvModalEvt) {
        bvModalEvt.preventDefault()
        const res = await this.createProduct()
        if (res.successes.length > 0) {
          this.$emit('productCreated', this.skuInput)
          this.$nextTick(() => {
            this.$bvModal.hide(`modal-add-products`)
          })
        } else {
          this.$emit(
            'error',
            res.errors?.[0]?.message || 'An error has occured',
          )
        }
      },

      async skuValidation() {
        // Rule: No spaces, \r, \n, or carriage return the SKU input field can accept only numbers, letters, ","
        const invalidCharacters = /^[a-zA-Z0-9,]*$/
        this.isSkuValid = invalidCharacters.test(this.skuInput)

        if (this.isSkuValid) {
          const res = await Functions.getProductFromCatalog(
            this.tenant,
            this.catalogConnectorId,
            this.skuInput,
          )
          this.isSkuUnique = res?.size === 0
          return this.isSkuUnique
        }
        return false
      },
      async saveAndAdd() {
        this.appLoading(true)
        this.submitLabel = 'Sending product...'
        const res = await this.createProduct()
        if (res.success) {
          setTimeout(() => {
            this.submitLabel = 'Sent !'
            this.isSent = true
            const scrollContainer = document.querySelector(
              '#modal-add-products .modal-body',
            )
            if (scrollContainer) {
              scrollContainer.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
            this.appLoading(false)
            this.$emit('productCreated', this.skuInput)
            setTimeout(() => {
              this.isSent = false // Revert isSent back to false
              this.resetValues()
            }, 1000)
          }, 2000)
        } else {
          this.appLoading(false)
          this.$emit(
            'error',
            res.errors?.[0]?.message || 'An error has occured',
          )
          this.submitLabel = initialData.submitLabel
        }
      },
      async createProduct() {
        const payload = {
          actions: [
            {
              action: 'add',
              product: {
                sku: this.skuInput,
                brandCode: this.brandInput.id,
                countryCode: this.countryCode,
                name: this.nameInput,
                shortDescription: this.descriptionInput ?? '',
                packshotImageUrl: this.packshotUrlInput ?? '',
                pdpUrl: this.pdpUrlInput ?? '',
                eCommerce: {
                  clickToBuyUrl: this.clickToBuyURLInput ?? '',
                  color: this.colorInput ?? '',
                },
                hexValue: this.hexValueInput ?? '',
                shadeImagePath: this.shadeImagePathInput ?? '',
                shadeName: this.shadeNameInput ?? '',
                franchise: this.franchiseInput ?? '',
              },
            },
          ],
        }

        const res = await Functions.bulkActionsProductsForLocalCatalog(
          this.tenant,
          this.catalogConnectorId,
          payload,
        )
        await this['products/loadCatalogConnector']({
          catalogConnectorId: this.catalogConnectorId,
          tenant: this.tenant,
        })
        return res
      },

      resetValues() {
        this.appLoading(false)
        Object.keys(initialData).forEach((key) => {
          this.$data[key] = initialData[key]
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .allow-white-spaces {
    white-space: pre-wrap;
    line-height: 1rem;
  }

  .submit-button,
  .mt-2 {
    transition: background-color 0.5s ease;
    /* Smooth transition for background-color change */
    /* Include other properties you might want to transition, e.g., color for text color */
    transition: color 0.5s ease, background-color 0.5s ease;
  }

  .button-sent {
    background-color: var(--success) !important;
    /* Desired end state color */
    border-color: var(--success);
    color: white !important;
    /* Optional: change text color for better contrast */
    /* No need to include the transition property here, it's inherited from the button's default state */
  }
</style>
